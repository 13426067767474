<template>
  <v-form class="ma-2"
          ref="form"
          v-model="valid"
          lazy-validation>
    <v-dialog v-model="validationDialog"
              type="error"
              width="500">
      <v-alert type="error">
        Une erreur s'est produite.
      </v-alert>
    </v-dialog>
    <v-row>
      <v-col class="required">
        <v-text-field v-model="form.type"
                      :rules="[v => !!v || 'Le type est requis']"
                      label="Type"
                      required>
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model="form.description"
                      label="Description"
                      >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model.number="form.price"
                      label="Montant"
                      ></v-text-field>
      </v-col>
      <v-col>
        <v-menu v-model="dateDialog"
                :close-on-content-click="false"
                :nudge-right="0"
                transition="scale-transition"
                offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="form.date"
                          label="Date"
                          v-bind="attrs"
                          v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="form.date"
                         @input="dateDialog = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu v-model="cdeDialog"
                :close-on-content-click="false"
                :nudge-right="0"
                transition="scale-transition"
                offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="form.cde"
                          label="CDE"
                          v-bind="attrs"
                          v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="form.cde"
                         @input="cdeDialog = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-row class="d-flex flex-nowrap ml-2" align="center">
          <v-autocomplete v-model="form.percent"
                          :items="propositionStates.items"
                          item-text="name"
                          item-value="id"
                          dense
                          :loading="loadingPropositionStates"
                          :label="propositionStates.catalog.name"></v-autocomplete>
          <v-btn fab
                 x-small
                 dark
                 class="ma-auto ml-2 mt-3"
                 @click="editCatalog(propositionStates.catalog.id)"
                 color="#6DD5CC"
                 style="color:white;">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
        <v-dialog v-model="catalogDialog"
                  width="500">
          <CatalogForm :catalogId="selectedCatalog" v-on:closeCatalog="closeCatalog" />
        </v-dialog>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { getCatalogByName } from '@/services/catalog.service';
import CatalogForm from '@/components/CatalogForm.vue';
import { mapActions } from 'vuex';

export default {
  name: 'PropositionForm',
  props: ['proposition'],
  components: {
    CatalogForm,
  },
  data: () => ({
    valid: true,
    validationDialog: false,
    dateDialog: false,
    cdeDialog: false,
    loadingPropositionStates: false,
    propositionStates: { catalog: { name: '' }, items: [] },
    catalogDialog: false,
    selectedCatalog: null,
  }),
  computed: {
    form() {
      return this.proposition ? this.proposition : {};
    },
  },
  created() {
    this.fetchCatalogs();
  },
  methods: {
    fetchCatalogs() {
      this.loadingPropositionStates = true;
      getCatalogByName('Etat%20de%20la%20proposition').then((res) => {
        this.propositionStates = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingPropositionStates = false;
      });
    },
    editCatalog(catalogId) {
      this.catalogDialog = true;
      this.selectedCatalog = catalogId;
    },
    closeCatalog() {
      this.fetchCatalogs();
      this.catalogDialog = false;
      this.selectedCatalog = null;
    },
    validate() {
      // send to parent
      if (this.$refs.form.validate()) {
        if (this.form.percent) {
          this.form.percent = this.propositionStates.items.find((el) => el.id === this.form.percent)
            || this.form.percent;
        }
        return this.form;
      }
      return null;
    },
    ...mapActions({
      register: 'auth/register',
    }),
  },
};
</script>

<style scoped>

</style>
