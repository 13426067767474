import axios from 'axios';
import authHeader from './auth-header';

export function getSubscriptions() {
  return axios.get(`${process.env.VUE_APP_API_URL}subscriptions`, { headers: authHeader() });
}

export function getSubscription(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}subscriptions/${encodeURIComponent(id)}`, { headers: authHeader() });
}

export function getSubscriptionsByOrganisation(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}subscriptions/organisation/${encodeURIComponent(id)}`, { headers: authHeader() });
}

export function createSubscription(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}subscriptions/`, data, { headers: authHeader() });
}

export function updateSubscription(id, data) {
  return axios.patch(`${process.env.VUE_APP_API_URL}subscriptions/${encodeURIComponent(id)}`, data, { headers: authHeader() });
}

export function deleteSubscription(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}subscriptions/${id}`, { headers: authHeader() });
}
