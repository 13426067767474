var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',{staticClass:"header"},[_c('v-alert',{attrs:{"value":_vm.alert,"type":_vm.type,"border":"right","dense":"","dismissible":"","transition":"scale-transition"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('h1',[_vm._v(" Contacts ")]),_c('p',[_vm._v(" Répertorier ici tous vos contacts client, en les ajoutant et modifiant. ")])],1),_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.contacts,"search":_vm.search,"items-per-page":10,"calculate-widths":"","loading":_vm.loading},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"data",attrs:{"align":"center"}},[_c('v-text-field',{staticClass:"research",attrs:{"label":"Rechercher","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"rounded":"","color":"#6DD5CC","dark":"","to":{ name: 'dashboard_new_contact' }}},[_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Ajouter un contact ")],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","fullscreen":_vm.$vuetify.breakpoint.mdAndDown,"max-width":"1200px"},model:{value:(_vm.dialogNew),callback:function ($$v) {_vm.dialogNew=$$v},expression:"dialogNew"}},[_c('router-view')],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","fullscreen":_vm.$vuetify.breakpoint.mdAndDown,"max-width":"1200px"},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('router-view')],1)],1)]},proxy:true},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{
          'font-weight-medium': item.admin,
          'font-weight-regular': !item.admin,
        }},[_vm._v(" "+_vm._s(item.admin ? 'Administrateur' : 'Client')+" ")])]}},{key:"item.customer",fn:function(ref){
        var item = ref.item;
return [(item.Customer)?_c('router-link',{attrs:{"to":_vm.getCustomerTo(item.Customer)}},[_vm._v(" Lien ")]):_c('span',[_vm._v("Non")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{ name: 'dashboard_edit_contact', params:
          { id: encodeURIComponent(item.id) } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.popDelete(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}},{key:"no-results",fn:function(){return [_vm._v(" Aucun résultat trouvé :/ ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" Aucun contact n'a pu être récupéré :/ ")]},proxy:true}])})],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirmation de la suppression ")]),_c('v-card-text',[_vm._v(" Êtes-vous sûr de vouloir supprimer ce contact ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v(" Annuler ")]),_c('v-btn',{staticStyle:{"color":"white"},attrs:{"color":"#6DD5CC","rounded":""},on:{"click":function($event){return _vm.deleteContact(_vm.rowID)}}},[_vm._v(" Supprimer ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }