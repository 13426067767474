import axios from 'axios';
import authHeader from './auth-header';

export function getOrganisations() {
  return axios.get(`${process.env.VUE_APP_API_URL}organisations`, { headers: authHeader() });
}

export function getOrganisation(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}organisations/${encodeURIComponent(id)}`, { headers: authHeader() });
}

export function createOrganisation(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}organisations/`, data, { headers: authHeader() });
}

export function updateOrganisation(id, data) {
  return axios.patch(`${process.env.VUE_APP_API_URL}organisations/${encodeURIComponent(id)}`, data, { headers: authHeader() });
}

export function deleteOrganisation(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}organisations/${id}`, { headers: authHeader() });
}
