import axios from 'axios';
import authHeader from './auth-header';

export function getCatalogs(itemsPerPage, page) {
  return axios.get(`${process.env.VUE_APP_API_URL}catalogs?page=${page || 0}&limit=100`, { headers: authHeader() });
}

export function getCatalog(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}catalogs/${id}`, { headers: authHeader() });
}

export function getCatalogByName(name) {
  return axios.get(`${process.env.VUE_APP_API_URL}catalogs/name/${name}`, { headers: authHeader() });
}

export function createCatalog(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}catalogs`, data, { headers: authHeader() });
}

// update catalog name
export function updateCatalog(id, data) {
  return axios.patch(`${process.env.VUE_APP_API_URL}catalogs/${id}`, data, { headers: authHeader() });
}

export function deleteCatalog(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}catalogs/${encodeURIComponent(id)}`, { headers: authHeader() });
}

export function getAllCatalogItems() {
  return axios.get(`${process.env.VUE_APP_API_URL}catalogItems`, { headers: authHeader() });
}

// get items by catalog
export function getCatalogItems(catalogId) {
  return axios.get(`${process.env.VUE_APP_API_URL}catalogItems/${catalogId}`, { headers: authHeader() });
}

// add a catalog item to a catalog by id (id is in data)
export function createCatalogItem(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}catalogItems`, data, { headers: authHeader() });
}

export function updateCatalogItem(id, data) {
  return axios.patch(`${process.env.VUE_APP_API_URL}catalogItems/${id}`, data, { headers: authHeader() });
}

// remove a catalog item
export function deleteCatalogItem(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}catalogItems/${id}`, { headers: authHeader() });
}
