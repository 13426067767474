<template>
  <v-card>
    <v-app-bar style="
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 80px;
      background: rgb(113,213,197);
      background: linear-gradient(90deg, rgba(113,213,197,1) 0%, rgba(148,228,165,1) 100%);
      color: white;"
      flat>
      <v-card-title>
          <span class="headline" style='color: white;'>Nouveau contact</span>
      </v-card-title>
    </v-app-bar>
    <v-dialog v-model="validationDialog"
              type="error"
              width="500">
      <v-alert type="error">
        Certains champs sont invalides.
      </v-alert>
    </v-dialog>

    <v-card-text>
      <v-container class="mt-4">
        <ContactForm ref="form" />
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="black"
          text
          exact
          :to="{ name: 'dashboard_contacts' }"
        >
          Annuler
        </v-btn>
        <v-btn
          color="#6DD5CC"
          exact
          @click="validate"
          style='color: white;'
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { createContact } from '@/services/contact.service';
import ContactForm from '@/components/ContactForm.vue';
import { mapActions } from 'vuex';
// import alert from '@/views/bo/contacts.vue';

export default {
  name: 'Contact',
  components: {
    ContactForm,
  },

  data: () => ({
    validationDialog: false,
  }),
  methods: {
    validate() {
      if (this.$refs.form) {
        const valid = this.$refs.form.validate();
        if (valid) {
          createContact(valid).then(() => {
            this.$router.push({ name: 'dashboard_contacts', params: { type: 'success', text: 'Le contact a été créé avec succès' } });
          });
        }
      }
    },

    ...mapActions({
      register: 'auth/register',
    }),
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
  v-app-bar {
    background: rgb(113,213,197);
    background: linear-gradient(90deg, rgba(113,213,197,1) 0%, rgba(148,228,165,1) 100%);
  }
</style>
