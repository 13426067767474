<template>
  <v-card>
    <v-app-bar style="
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 80px;
      background: rgb(113,213,197);
      background: linear-gradient(90deg, rgba(113,213,197,1) 0%, rgba(148,228,165,1) 100%);
      color: white;"
      flat>
      <v-card-title>
        <span class="headline">Édition du contact
          <span v-if="contact">
            {{ contact.firstname || '' }}
            {{ contact.lastname || '' }}
          </span></span>
      </v-card-title>
    </v-app-bar>
    <v-dialog v-model="validationDialog"
              type="error"
              width="500">
      <v-alert type="error">
        Certains champs sont invalides.
      </v-alert>
    </v-dialog>

    <v-card-text>
      <v-container class="mt-4">
        <ContactForm ref="form" :isEditingContact="contact" v-if="contact"/>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="black"
          text
          exact
          :to="{ name: 'dashboard_contacts' }"
        >
          Annuler
        </v-btn>
        <v-btn
          color="#6DD5CC"
          rounded
          style="color: white;"
          exact
          @click="editItem"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import ContactForm from '@/components/ContactForm.vue';
import { getContact, updateContact } from '../../services/contact.service';

export default {
  name: 'edit-contact',
  components: {
    ContactForm,
  },
  data: () => ({
    contact: null,
    validationDialog: false,
    alert: null,
  }),
  methods: {
    editItem() {
      console.log('valid');
      if (this.$refs.form) {
        console.log('valid');
        const valid = this.$refs.form.validate();
        console.log(valid);
        if (valid) {
          if (valid.service && valid.service.id) {
            valid.service = valid.service.id;
          }
          if (valid.owner && valid.owner.id) {
            valid.owner = valid.owner.id;
          }
          if (valid.organisation && valid.organisation.id) {
            valid.organisation = valid.organisation.id;
          }
          updateContact(this.contact.id, valid).then(() => {
            this.$router.push({ name: 'dashboard_contacts', params: { type: 'success', text: 'La modification a été effectuée avec succès' } });
          });
        }
      }
    },
    async fetchContact(newRoute) {
      this.alert = newRoute.params.alert;
      getContact(decodeURIComponent(newRoute.params.id)).then((value) => {
        this.contact = value.data;
        /*
        this.newContact.formations = this.newContact.formations.map((el) => (
          { formation: el.formation.id, level: el.level.id }
        ));
        this.newContact.service = this.newContact.service.id;
        this.newContact.owner = this.newContact.owner.id;
        this.newContact.organisation = this.newContact.organisation.id;
        */
        console.log(this.contact);
      })
        .catch((err) => console.log(err));
    },
  },
  watch: {
    $route: {
      handler: 'fetchContact',
      immediate: true,
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
