import axios from 'axios';
import authHeader from './auth-header';

export function getPropositions() {
  return axios.get(`${process.env.VUE_APP_API_URL}propositions`, { headers: authHeader() });
}

export function getProposition(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}propositions/${encodeURIComponent(id)}`, { headers: authHeader() });
}

export function getPropositionsByOrganisation(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}propositions/organisation/${encodeURIComponent(id)}`, { headers: authHeader() });
}

export function createProposition(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}propositions/`, data, { headers: authHeader() });
}

export function updateProposition(id, data) {
  return axios.patch(`${process.env.VUE_APP_API_URL}propositions/${encodeURIComponent(id)}`, data, { headers: authHeader() });
}

export function deleteProposition(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}propositions/${id}`, { headers: authHeader() });
}
