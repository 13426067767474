<template>
  <v-app>
    <div class='header'>
      <v-alert
        :value="alert"
        :type="type"
        border="right"
        dense
        dismissible
        transition="scale-transition"
      > {{message}} </v-alert>
      <h1>
        Organisations
      </h1>
      <p>
        Répertorier ici toutes les organisations de vos clients,
        en les ajoutant et modifiant et en les liant à vos clients.
      </p>
    </div>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="organisations"
        :items-per-page="10"
        :search="search"
        calculate-widths
        :loading="loading"
        @click:row="handleClick"
      >
        <template #top>
            <v-row align="center" class="data">
              <v-text-field
                v-model="search"
                label="Rechercher"
                append-icon="mdi-magnify"
                single-line
                hide-details
                class='research'
              />
              <v-spacer />
              <v-btn
                rounded
                color="#6DD5CC"
                dark
                class="mb-2"
                :to="{ name: 'dashboard_new_organisation' }"
              >
              <v-icon right>
                  mdi-plus
                </v-icon>
                    Ajouter une organisation
              </v-btn>
              <v-dialog
                v-model="dialogNew"
                persistent
                scrollable
                :fullscreen="$vuetify.breakpoint.mdAndDown"
                max-width="1200px"
              >
                <router-view />
              </v-dialog>
              <v-dialog
                v-model="dialogEdit"
                persistent
                scrollable
                :fullscreen="$vuetify.breakpoint.mdAndDown"
                max-width="1200px"
              >
                <router-view />
              </v-dialog>
              <v-dialog
                v-model="dialogDelete"
                max-width="400"
              >
                <v-card>
                  <v-card-title class="text-h5">
                    Confirmation de la suppression
                  </v-card-title>

                  <v-card-text>
                    Êtes-vous sûr de vouloir supprimer ce contact ?
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="black"
                      text
                      @click="dialogDelete = false"
                    >
                      Annuler
                    </v-btn>

                    <v-btn
                      color="#6DD5CC"
                      rounded
                      style="color: white;"
                      @click="deleteOrganisation(rowID)"
                    >
                      Supprimer
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
        </template>

        <!-- <template #item.name="{ item }">
          <v-btn text
                :to="{ name: 'dashboard_edit_organisation',
                params: { id: encodeURIComponent(item.id) }}">
            {{ item.name }}
          </v-btn>
        </template> -->

        <template #no-results>
          Aucun résultat trouvé :/
        </template>

        <template #no-data>
          Aucune organisation n'a pu être récupérée :/
        </template>

        <template #item.actions="{ item }">
          <v-btn
            icon
            class="mr-2"
            :to="{ name: 'dashboard_edit_organisation', params:
            { id: encodeURIComponent(item.id) } }"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click.stop="popDelete(item.id)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template #no-results>
          Aucun résultat trouvé :/
        </template>

        <template #no-data>
          Aucune organisation n'a pu être récupérée :/
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>

<script>
import { getOrganisations, deleteOrganisation } from '@/services/organisation.service';

export default {
  name: 'Organisations',

  created() {
    this.fetchOrganisations();
  },

  data: () => ({
    headers: [
      {
        text: 'Nom',
        align: 'start',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Téléphone',
        value: 'phone',
      },
      {
        text: 'Siret',
        value: 'siret',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
      },
    ],
    loading: false,
    search: null,
    organisations: [],
    dialogNew: false,
    dialogEdit: false,
    dialogDelete: false,
    show: true,
    type: null,
    message: null,
    rowID: null,
    alert: false,
  }),
  methods: {
    fetchOrganisations() {
      this.loading = true;
      getOrganisations()
        .then((value) => {
          this.organisations = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    handleClick(row) {
      console.log(this.show);
      if (this.show) {
        this.$router.push({ name: 'dashboard_edit_organisation', params: { id: encodeURIComponent(row.id), alert: this.alert } });
        this.alert = this.newRoute.params.alert;
      } else {
        this.show = true;
      }
      console.log(this.show);
    },
    getOrganisationTo(id) {
      return {
        name: 'dashboard_edit_client',
        params: {
          clientId: id,
        },
      };
    },
    popDelete(id) {
      this.rowID = id;
      this.dialogDelete = true;
      console.log(this.rowID);
    },
    deleteOrganisation(id) {
      this.show = false;
      this.dialogDelete = false;
      deleteOrganisation(id)
        .then(() => {
          this.startAlert('success', 'La suppression a été effectuée avec succès');
          this.fetchOrganisations();
        })
        .catch(console.error);
    },
    startAlert(type, text) {
      this.message = text;
      this.type = type;
      this.alert = true;
      setTimeout(() => {
        this.alert = false;
        console.log(this.alert);
      }, 5000);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        console.log(newRoute.params.type);
        if (newRoute.params.type != null) {
          this.startAlert(newRoute.params.type, newRoute.params.text);
        }
        this.fetchOrganisations();
      },
    },
  },
};
</script>

<style scoped>
  .research {
    background-color: #F5F5F5;
    border-bottom: none;
  }
  .v-application--is-ltr .v-btn__content .v-icon--right {
    margin-left: 0px;
    margin-right: 8px;
  }
  .header {
    padding:50px;
    padding-left:8.5%;
    margin-bottom: 25px;
    color:white;
    max-height: 188px;
    width: 100vw;
    background: rgb(113,213,197);
    background: linear-gradient(90deg, rgba(113,213,197,1) 0%, rgba(148,228,165,1) 100%);
  }
  .container {
    margin-left: 7.5%;
    margin-right: 4%;
  }
  @media (min-width: 1264px) {
    .container {
      max-width: 85%;
    }
  }
  .data {
    margin-left:0%;
    margin-right: 0%;
    margin-bottom: 25px;
  }
  .v-text-field {
    padding-top: 2px;
    margin-top:0px;
  }
  #input-50 {
    padding-left:10px;
  }
  .v-btn--is-elevated {
    box-shadow: none;
  }
  .v-alert {
    width: fit-content;
    position: fixed;
    bottom: 10px;
    right: 10px;
  }
</style>
