<template>
  <v-form class="ma-2"
          ref="form"
          v-model="valid"
          lazy-validation>
    <v-dialog v-model="validationDialog"
              type="error"
              width="500">
      <v-alert type="error">
        Une erreur s'est produite.
      </v-alert>
    </v-dialog>
    <v-row>
      <v-col cols="4" class="required">
        <v-row class="d-flex flex-nowrap ml-2">
          <v-autocomplete v-model="form.name"
                          :items="externalProducts.items"
                          item-text="name"
                          item-value="id"
                          dense
                          :rules="[v => !!v || 'Le produit est requis']"
                          :loading="loadingExternalProducts"
                          :label="externalProducts.catalog.name"></v-autocomplete>
          <v-btn fab
                 x-small
                 dark
                 class="ma-auto ml-2 mt-3"
                 @click="editCatalog(externalProducts.catalog.id)"
                 color="#6DD5CC"
                 style="color:white;">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
        <v-dialog v-model="catalogDialog"
                  width="500">
          <CatalogForm :catalogId="selectedCatalog" v-on:closeCatalog="closeCatalog" />
        </v-dialog>
      </v-col>
      <v-col cols="3" class="pt-0">
        <v-text-field v-model="form.url"
                      label="Url"
                      required>
        </v-text-field>
      </v-col>
      <v-col cols="4">
        <v-row class="d-flex flex-nowrap">
          <v-autocomplete v-model="form.service_provider"
                          :items="organisations"
                          item-text="name"
                          item-value="id"
                          dense
                          :loading="loadingOrganisations"
                          required
                          label="Prestataire"></v-autocomplete>
          <v-btn fab
                 x-small
                 dark
                 class="ma-auto ml-2 mt-3"
                 @click="openOrganisation"
                 color="#6DD5CC"
                 style="color:white;">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
        <v-dialog v-model="organisationDialog"
                  persistent
                  width="800">
          <v-card>
            <v-app-bar style="
              display: flex;
              justify-content: center;
              align-items: center;
              max-height: 80px;
              background: rgb(113,213,197);
              background: linear-gradient(90deg, rgba(113,213,197,1) 0%, rgba(148,228,165,1) 100%);
              color: white;"
              flat>
              <v-card-title class="text-h5">
                Nouvelle organisation
              </v-card-title>
            </v-app-bar>

            <v-card-text>
              <OrganisationForm ref="organisationForm" />
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="black"
                     text
                     @click="organisationDialog = false">
                Annuler
              </v-btn>
              <v-btn rounded color="#6DD5CC"
                     @click="validateOrganisation"
                     style="color:white;">
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="3" class="pt-0">
        <v-menu v-model="startDateDialog"
                :close-on-content-click="false"
                :nudge-right="0"
                transition="scale-transition"
                offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="form.start_date"
                          label="Début"
                          required
                          v-bind="attrs"
                          v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="form.start_date"
                         @input="startDateDialog = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" class="pt-0">
        <v-menu v-model="deadlineDialog"
                :close-on-content-click="false"
                :nudge-right="0"
                transition="scale-transition"
                offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="form.deadline"
                          label="Fin"
                          required
                          v-bind="attrs"
                          v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="form.deadline"
                         @input="deadlineDialog = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3" class="required pt-0">
        <v-text-field v-model.number="form.price"
                      :rules="priceRules"
                      label="Montant"
                      required></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { getCatalogByName } from '@/services/catalog.service';
import CatalogForm from '@/components/CatalogForm.vue';
import { getOrganisations, createOrganisation } from '@/services/organisation.service';
import OrganisationForm from '@/components/OrganisationForm.vue';
import { mapActions } from 'vuex';

export default {
  name: 'EquipmentForm',
  props: ['equipment'],
  components: {
    OrganisationForm,
    CatalogForm,
  },
  data: () => ({
    priceRules: [
      (v) => !!v || 'Le montant est requis',
      (v) => !Number.isNaN(Number(v)) || 'Le prix doit être un chiffre.',
    ],
    valid: true,
    validationDialog: false,
    startDateDialog: false,
    deadlineDialog: false,
    loadingOrganisations: false,
    organisations: [],
    organisationDialog: false,
    loadingExternalProducts: false,
    externalProducts: { catalog: { name: '' }, items: [] },
    catalogDialog: false,
    selectedCatalog: null,
  }),
  computed: {
    form() {
      return this.equipment ? this.equipment : {};
    },
  },
  created() {
    this.fetchOrganisations();
    this.fetchCatalogs();
  },
  methods: {
    fetchCatalogs() {
      this.loadingExternalProducts = true;
      getCatalogByName('Produit%20externe').then((res) => {
        this.externalProducts = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingExternalProducts = false;
      });
    },
    editCatalog(catalogId) {
      this.catalogDialog = true;
      this.selectedCatalog = catalogId;
    },
    closeCatalog() {
      this.fetchCatalogs();
      this.catalogDialog = false;
      this.selectedCatalog = null;
    },
    fetchOrganisations() {
      this.loadingOrganisations = true;
      getOrganisations().then((res) => {
        console.log(res);
        this.organisations = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOrganisations = false;
      });
    },
    validate() {
      // send to parent
      if (this.$refs.form.validate()) {
        if (this.form.name) {
          this.form.name = this.externalProducts.items.find((el) => el.id === this.form.name)
            || this.form.name;
        }
        if (this.form.service_provider) {
          this.form.service_provider = this.organisations.find(
            (el) => el.id === this.form.service_provider,
          )
            || this.form.service_provider;
        }
        return this.form;
      }
      return null;
    },
    openOrganisation() {
      this.organisationDialog = true;
    },
    validateOrganisation() {
      const valid = this.$refs.organisationForm.validate();
      if (valid) {
        createOrganisation(valid).then((res) => {
          console.log(res);
          this.organisationDialog = false;
          this.fetchOrganisations();
        }).catch((err) => {
          console.log(err);
          this.organisationDialog = false;
        });
      } else {
        this.validationDialog = true;
      }
    },
    ...mapActions({
      register: 'auth/register',
    }),
  },
};
</script>

<style scoped>

</style>
