import axios from 'axios';
import authHeader from './auth-header';

export function getMaintenances() {
  return axios.get(`${process.env.VUE_APP_API_URL}maintenances`, { headers: authHeader() });
}

export function getMaintenance(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}maintenances/${encodeURIComponent(id)}`, { headers: authHeader() });
}

export function getMaintenancesByOrganisation(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}maintenances/organisation/${encodeURIComponent(id)}`, { headers: authHeader() });
}

export function createMaintenance(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}maintenances/`, data, { headers: authHeader() });
}

export function updateMaintenance(id, data) {
  return axios.patch(`${process.env.VUE_APP_API_URL}maintenances/${encodeURIComponent(id)}`, data, { headers: authHeader() });
}

export function deleteMaintenance(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}maintenances/${id}`, { headers: authHeader() });
}
