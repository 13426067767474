<template>
  <v-card>
    <v-app-bar style="
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 80px;
      background: rgb(113,213,197);
      background: linear-gradient(90deg, rgba(113,213,197,1) 0%, rgba(148,228,165,1) 100%);
      color: white;"
      flat>
      <v-card-title>
        <span class="headline">Édition de l'organisation {{ form.name || '' }}</span>
      </v-card-title>
    </v-app-bar>
    <v-dialog v-model="validationDialog"
              type="error"
              width="500">
      <v-alert type="error">
        Certains champs sont invalides.
      </v-alert>
    </v-dialog>

    <v-card-text>
      <v-container class="mt-4">
        <OrganisationForm ref="formOrganisation" :organisation="form" />
        <v-divider />
        <v-row align='center'>
          <v-col cols="12">
            <h2 class="mb-8" style="color: black;">Contacts</h2>
          </v-col>
          <v-row class="ma-2 ml-0 d-flex contacts" style="flex-direction: column;">
            <v-col cols="6">
              <v-autocomplete v-model="selectedContact"
                              :items="contactsToSelect"
                              item-text="lastname"
                              item-value="id"
                              dense
                              :loading="loadingContacts"
                              label="Sélectionner un contact"></v-autocomplete>
            </v-col>
            <v-btn fab x-small rounded color="#6DD5CC"
                  @click="addSelectedContact" class="ml-2 mb-8"
                  style="color: white;top: 14px;">
              <v-icon dark>
                mdi-plus
              </v-icon>
              <span class="d-sr-only">Ajouter un contact</span>
            </v-btn>
          </v-row>
          <v-row>
            <v-btn rounded color="#6DD5CC"
                @click="addContact" class="ml-2 mb-8"
                style="color: white;top:10px;left: 10px;">
              <v-icon dark>
                mdi-plus
              </v-icon>
              <span>Ajouter un contact</span>
            </v-btn>
          </v-row>
        </v-row>
        <v-container>
          <v-row v-for="(item, id) in formContacts" :key="id">
            <v-col>
              <ContactForm ref="formContacts"
                           :isEditingContact="item"
                           :isInsideOrganisation="true"
                           v-if="isEditingContact === id" />
              <v-container v-else>
                <v-row style="border-bottom:solid 1px grey;" class="mb-4">
                  <v-col cols="2">{{item.lastname}}</v-col>
                  <v-col cols="2">{{item.firstname}}</v-col>
                  <v-col cols="2">{{item.email}}</v-col>
                  <v-col cols="2">{{item.phone}}</v-col>
                  <v-col cols="2">{{item.phone_mobile}}</v-col>
                  <v-col cols="2">{{item.role}}</v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="editContact(id)" v-if="isEditingContact !== id">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="stopEditingContact(id)" v-else>
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon @click="deleteContact(id)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-row class="ma-2 ml-0 d-flex align-center">
          <v-col cols="12">
            <h2 style="color: black;">Maintenances</h2>
          </v-col>
          <v-btn rounded color="#6DD5CC"
                @click="addNewMaintenance" class="ml-2"
                style="color: white;">
            <v-icon dark>
              mdi-plus
            </v-icon>
            <span>Ajouter une maintenance</span>
          </v-btn>
        </v-row>
        <v-container>
          <v-row v-for="(item, id) in formMaintenances" :key="id">
            <v-col>
              <MaintenanceForm ref="formMaintenances"
                               :maintenance="item"
                               v-if="isEditingMaintenance === id" />
              <v-container v-else>
                <v-row style="border-bottom:solid 1px grey;" class="mb-4">
                  <v-col cols="2">{{item.product ? item.product.name : ''}}</v-col>
                  <v-col cols="2">{{item.url}}</v-col>
                  <v-col cols="2">
                    <v-chip color="grey"
                            text-color="white"
                            v-if="item.subscription_date"
                            dark>
                      {{ moment(item.subscription_date).format('DD/MM/YY') }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <v-chip :color="new Date(item.deadline) <= (new Date())
                            .setHours(0,0,0,0) ? 'red' : 'green'"
                            text-color="white"
                            v-if="item.deadline"
                            dark>
                      {{ moment(item.deadline).format('DD/MM/YY') }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">{{item.price ? `${item.price} €` : ''}}</v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="editMaintenance(id)"
                     v-if="isEditingMaintenance !== id">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="stopEditingMaintenance(id)" v-else>
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon @click="deleteMaintenance(id)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-row class="ma-2 ml-0 d-flex align-center">
          <v-col cols="12">
            <h2 style="color: black;">Abonnements</h2>
          </v-col>
          <v-btn rounded color="#6DD5CC"
                @click="addSubscription" class="ml-2"
                style="color: white;">
            <v-icon dark>
              mdi-plus
            </v-icon>
            <span>Ajouter un abonnement</span>
          </v-btn>
        </v-row>
        <v-container>
          <v-row v-for="(item, id) in formSubscriptions" :key="id">
            <v-col>
              <SubscriptionForm ref="formSubscriptions"
                                :subscription="item"
                                v-if="isEditingSubscription === id" />
              <v-container v-else>
                <v-row style="border-bottom:solid 1px grey;" class="mb-4">
                  <v-col cols="2">
                    {{ item.name.name }}
                  </v-col>
                  <v-col cols="2">
                    <v-chip color="grey"
                            text-color="white"
                            v-if="item.start_date"
                            dark>
                      {{ moment(item.start_date).format('DD/MM/YY') }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <v-chip :color="new Date(item.deadline) <= (new Date())
                            .setHours(0,0,0,0) ? 'red' : 'green'"
                            text-color="white"
                            v-if="item.deadline"
                            dark>
                      {{ moment(item.deadline).format('DD/MM/YY') }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">{{item.price ? `${item.price} €` : ''}}</v-col>
                  <v-col cols="2">{{item.duration.name}}</v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="editSubscription(id)" v-if="isEditingSubscription !== id">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="stopEditingSubscription(id)" v-else>
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon @click="deleteSubscription(id)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-row class="ma-2 ml-0 d-flex align-center">
          <v-col cols="12">
            <h2 style="color: black;">Equipements</h2>
          </v-col>
          <v-btn rounded color="#6DD5CC"
                @click="addEquipment" class="ml-2"
                style="color: white;">
            <v-icon dark>
              mdi-plus
            </v-icon>
            <span>Ajouter un équipement</span>
          </v-btn>
        </v-row>
        <v-container>
          <v-row v-for="(item, id) in formEquipments" :key="id">
            <v-col>
              <EquipmentForm ref="formEquipments"
                             :equipment="item"
                             v-if="isEditingEquipment === id" />
              <v-container v-else>
                <v-row style="border-bottom:solid 1px grey;" class="mb-4">
                  <v-col cols="2">{{item.name.name}}</v-col>
                  <v-col cols="2">{{item.url}}</v-col>
                  <v-col cols="2">
                    {{item.service_provider ? item.service_provider.name : '' }}
                  </v-col>
                  <v-col cols="2">
                    <v-chip color="grey"
                            text-color="white"
                            v-if="item.start_date"
                            dark>
                      {{ moment(item.start_date).format('DD/MM/YY') }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <v-chip :color="new Date(item.deadline) <= (new Date())
                            .setHours(0,0,0,0) ? 'red' : 'green'"
                            text-color="white"
                            v-if="item.deadline"
                            dark>
                      {{ moment(item.deadline).format('DD/MM/YY') }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">{{item.price ? `${item.price} €` : ''}}</v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="editEquipment(id)" v-if="isEditingEquipment !== id">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="stopEditingEquipment(id)" v-else>
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon @click="deleteEquipment(id)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-row class="ma-2 ml-0 d-flex align-center">
          <v-col cols="12">
            <h2 style="color: black;">Propositions</h2>
          </v-col>
          <v-btn rounded color="#6DD5CC"
                @click="addProposition" class="ml-2"
                style="color: white;">
            <v-icon dark>
              mdi-plus
            </v-icon>
            <span>Ajouter une proposition</span>
          </v-btn>
        </v-row>
        <v-container>
          <v-row v-for="(item, id) in formPropositions" :key="id">
            <v-col>
              <PropositionForm ref="formPropositions"
                               :proposition="item"
                               v-if="isEditingProposition === id" />
              <v-container v-else>
                <v-row style="border-bottom:solid 1px grey; grid-gap:.5rem;" class="mb-4">
                  <v-col>{{item.type}}</v-col>
                  <v-col>{{item.description}}</v-col>
                  <v-col>{{item.price ? `${item.price} €` : ''}}</v-col>
                  <v-col>
                    <v-chip color="grey"
                            v-if="item.date"
                            text-color="white"
                            dark>
                      {{ moment(item.date).format('DD/MM/YY') }}
                    </v-chip>
                  </v-col>
                  <v-col>
                    <v-chip color="grey"
                            v-if="item.cde"
                            text-color="white"
                            dark>
                      {{ moment(item.cde).format('DD/MM/YY') }}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <v-chip v-if="item.percent"
                            :color="getColor(item.percent)"
                            dark>
                      {{ item.percent.name }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="editProposition(id)" v-if="isEditingProposition !== id">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="stopEditingProposition(id)" v-else>
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon @click="deleteProposition(id)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn color="black"
               text
               exact
               :to="{ name: 'dashboard_organisations' }">
          Annuler
        </v-btn>
        <v-btn color="#6DD5CC"
               rounded
               style="color: white;"
               exact
               @click="editItem">
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import moment from 'moment';
import MaintenanceForm from '@/components/MaintenanceForm.vue';
import SubscriptionForm from '@/components/SubscriptionForm.vue';
import ContactForm from '@/components/ContactForm.vue';
import EquipmentForm from '@/components/EquipmentForm.vue';
import PropositionForm from '@/components/PropositionForm.vue';
import OrganisationForm from '@/components/OrganisationForm.vue';
import { getOrganisations, getOrganisation, updateOrganisation } from '../../services/organisation.service';
import { getCatalogByName } from '../../services/catalog.service';
import {
  getMaintenances, getMaintenancesByOrganisation, updateMaintenance, createMaintenance,
  deleteMaintenance,
} from '../../services/maintenance.service';
import {
  getSubscriptionsByOrganisation, updateSubscription, createSubscription, deleteSubscription,
} from '../../services/subscription.service';
import {
  getEquipmentsByOrganisation, updateEquipment, createEquipment, deleteEquipment,
} from '../../services/equipment.service';
import {
  getPropositionsByOrganisation, updateProposition, createProposition, deleteProposition,
} from '../../services/proposition.service';
import {
  getContacts, getContactsByOrganisation, updateContact, createContact, deleteContact,
} from '../../services/contact.service';

export default {
  name: 'edit-organisation',
  components: {
    MaintenanceForm,
    SubscriptionForm,
    EquipmentForm,
    PropositionForm,
    OrganisationForm,
    ContactForm,
  },
  data: () => ({
    validationDialog: false,
    form: {
      date: new Date().toISOString().substr(0, 10),
    },
    valid: true,
    loadingContacts: false,
    contactsToSelect: [],
    loadingCategories: false,
    categories: { catalog: { name: '' }, items: [] },
    loadingOrigines: false,
    origines: { catalog: { name: '' }, items: [] },
    loadingForms: false,
    forms: { catalog: { name: '' }, items: [] },
    loadingSponsors: false,
    sponsors: [],
    loadingFamilies: false,
    families: { catalog: { name: '' }, items: [] },
    loadingSubfamilies: false,
    subfamilies: { catalog: { name: '' }, items: [] },
    formMaintenances: [],
    formSubscriptions: [],
    formContacts: [],
    formEquipments: [],
    formPropositions: [],
    emptyMaintenance: {
      organisation: null,
      price: null,
      url: null,
      contacts: [],
      subscription_date: null,
      deadline: null,
    },
    loadingMaintenances: false,
    loadingFormMaintenances: false,
    loadingSubscriptions: false,
    loadingFormSubscriptions: false,
    loadingFormContacts: false,
    loadingFormEquipments: false,
    loadingFormPropositions: false,
    maintenances: [],
    subscriptions: [],
    contacts: [],
    equipments: [],
    propositions: [],
    newMaintenance: null,
    isEditingMaintenance: null,
    isEditingSubscription: null,
    isEditingContact: null,
    isEditingEquipment: null,
    isEditingProposition: null,
    selectedContact: null,
  }),
  created() {
    this.fetchContacts();
  },
  methods: {
    fetchContacts() {
      this.loadingContacts = true;
      getContacts().then((res) => {
        this.contactsToSelect = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingContacts = false;
      });
    },
    moment(date) {
      return moment(date);
    },
    getColor(percent) {
      if (percent && percent.name === 'Signé') {
        return 'green';
      }
      if (percent && percent.name === 'Perdu') {
        return 'red';
      }
      return 'orange';
    },
    addNewMaintenance() {
      if (this.$refs.formMaintenances && this.$refs.formMaintenances[0]) {
        const valid = this.$refs.formMaintenances[0].validate();
        if (valid) {
          const tmpId = this.formMaintenances[this.isEditingMaintenance].id || null;
          this.formMaintenances[this.isEditingMaintenance] = valid;
          this.formMaintenances[this.isEditingMaintenance].id = tmpId;
          this.formMaintenances.unshift({
            organisation: null,
            price: null,
            url: null,
            contacts: [],
            subscription_date: null,
            deadline: null,
          });
          this.isEditingMaintenance = 0;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.formMaintenances.unshift({
          organisation: null,
          price: null,
          url: null,
          contacts: [],
          subscription_date: null,
          deadline: null,
        });
        this.isEditingMaintenance = 0;
      }
    },
    addSubscription() {
      if (this.$refs.formSubscriptions && this.$refs.formSubscriptions[0]) {
        const valid = this.$refs.formSubscriptions[0].validate();
        if (valid) {
          const tmpId = this.formSubscriptions[this.isEditingSubscription].id || null;
          this.formSubscriptions[this.isEditingSubscription] = valid;
          this.formSubscriptions[this.isEditingSubscription].id = tmpId;
          this.formSubscriptions.unshift({});
          this.isEditingSubscription = 0;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.formSubscriptions.unshift({});
        this.isEditingSubscription = 0;
      }
    },
    addContact() {
      if (this.$refs.formContacts && this.$refs.formContacts[0]) {
        const valid = this.$refs.formContacts[0].validate();
        if (valid) {
          const tmpId = this.formContacts[this.isEditingContact].id || null;
          this.formContacts[this.isEditingContact] = valid;
          this.formContacts[this.isEditingContact].id = tmpId;
          this.formContacts.unshift({});
          this.isEditingContact = 0;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.formContacts.unshift({});
        this.isEditingContact = 0;
      }
    },
    addEquipment() {
      if (this.$refs.formEquipments && this.$refs.formEquipments[0]) {
        const valid = this.$refs.formEquipments[0].validate();
        if (valid) {
          const tmpId = this.formEquipments[this.isEditingEquipment].id || null;
          this.formEquipments[this.isEditingEquipment] = valid;
          this.formEquipments[this.isEditingEquipment].id = tmpId;
          this.formEquipments.unshift({});
          this.isEditingEquipment = 0;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.formEquipments.unshift({});
        this.isEditingEquipment = 0;
      }
    },
    addProposition() {
      if (this.$refs.formPropositions && this.$refs.formPropositions[0]) {
        const valid = this.$refs.formPropositions[0].validate();
        if (valid) {
          const tmpId = this.formPropositions[this.isEditingProposition].id || null;
          this.formPropositions[this.isEditingProposition] = valid;
          this.formPropositions[this.isEditingProposition].id = tmpId;
          this.formPropositions.unshift({});
          this.isEditingProposition = 0;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.formPropositions.unshift({});
        this.isEditingProposition = 0;
      }
    },
    addSelectedContact() {
      if (this.$refs.formContacts && this.$refs.formContacts[0]) {
        const valid = this.$refs.formContacts[0].validate();
        if (valid) {
          this.formContacts[this.isEditingContact] = valid;
          if (!this.formContacts.find((el) => el.id === this.selectedContact)) {
            this.formContacts.unshift(
              this.contactsToSelect.find((el) => el.id === this.selectedContact),
            );
            this.selectedContact = null;
          }
        }
      } else {
        console.log('else');
        if (!this.formContacts.find((el) => el.id === this.selectedContact)) {
          this.formContacts.unshift(
            this.contactsToSelect.find((el) => el.id === this.selectedContact),
          );
          this.selectedContact = null;
        }
      }
    },
    editItem() {
      // validate orga
      const validOrga = this.$refs.formOrganisation.validate();

      // validate maintenances
      let validMaintenances = true;
      if (this.$refs.formMaintenances && this.$refs.formMaintenances[0]) {
        const valid = this.$refs.formMaintenances[0].validate();
        if (valid) {
          const tmpId = this.formMaintenances[this.isEditingMaintenance].id || null;
          this.formMaintenances[this.isEditingMaintenance] = valid;
          this.formMaintenances[this.isEditingMaintenance].id = tmpId;
        } else {
          validMaintenances = false;
        }
      }

      // validate subscriptions
      let validSubscriptions = true;
      if (this.$refs.formSubscriptions && this.$refs.formSubscriptions[0]) {
        const valid = this.$refs.formSubscriptions[0].validate();
        if (valid) {
          const tmpId = this.formSubscriptions[this.isEditingSubscription].id || null;
          this.formSubscriptions[this.isEditingSubscription] = valid;
          this.formSubscriptions[this.isEditingSubscription].id = tmpId;
        } else {
          validSubscriptions = false;
        }
      }

      // validate contacts
      let validContacts = true;
      if (this.$refs.formContacts && this.$refs.formContacts[0]) {
        const valid = this.$refs.formContacts[0].validate();
        if (valid) {
          const tmpId = this.formContacts[this.isEditingContact].id || null;
          this.formContacts[this.isEditingContact] = valid;
          this.formContacts[this.isEditingContact].id = tmpId;
        } else {
          validContacts = false;
        }
      }

      // validate equipments
      let validEquipments = true;
      if (this.$refs.formEquipments && this.$refs.formEquipments[0]) {
        const valid = this.$refs.formEquipments[0].validate();
        if (valid) {
          const tmpId = this.formEquipments[this.isEditingEquipment].id || null;
          this.formEquipments[this.isEditingEquipment] = valid;
          this.formEquipments[this.isEditingEquipment].id = tmpId;
        } else {
          validEquipments = false;
        }
      }

      // validate propositions
      let validPropositions = true;
      if (this.$refs.formPropositions && this.$refs.formPropositions[0]) {
        const valid = this.$refs.formPropositions[0].validate();
        if (valid) {
          const tmpId = this.formPropositions[this.isEditingProposition].id || null;
          this.formPropositions[this.isEditingProposition] = valid;
          this.formPropositions[this.isEditingProposition].id = tmpId;
        } else {
          validPropositions = false;
        }
      }
      if (validOrga && validMaintenances && validSubscriptions
        && validEquipments && validPropositions && validContacts) {
        if (validOrga.societyType && validOrga.societyType.id) {
          validOrga.societyType = validOrga.societyType.id;
        }
        updateOrganisation(validOrga.id, validOrga).then((res) => {
          const newId = res.data.organisation.id;
          // validate maintenances
          this.formMaintenances.forEach(async (el) => {
            if (el.id) {
              // update
              const m = el;
              if (m.product.id) {
                m.product = m.product.id;
              }
              m.contacts = m.contacts.map((contact) => (contact.id ? contact.id : contact));
              await updateMaintenance(el.id, m);
            } else {
              // create
              const m = el;
              m.organisation = newId;
              if (m.product && m.product.id) {
                m.product = m.product.id;
              }
              m.contacts = m.contacts.map((contact) => (contact.id ? contact.id : contact));
              // await updateMaintenance(el.id, { organisation: newId });
              await createMaintenance(m);
            }
          });
          // validate subscriptions
          this.formSubscriptions.forEach(async (el) => {
            if (el.id) {
              // update
              const m = el;
              if (m.organisation && m.organisation.id) {
                m.organisation = m.organisation.id;
              }
              if (m.name && m.name.id) {
                m.name = m.name.id;
              }
              if (m.duration && m.duration.id) {
                m.duration = m.duration.id;
              }
              await updateSubscription(m.id, m);
            } else {
              // create
              const m = el;
              m.organisation = newId;
              if (m.name && m.name.id) {
                m.name = m.name.id;
              }
              if (m.duration && m.duration.id) {
                m.duration = m.duration.id;
              }
              await createSubscription(m);
            }
          });

          // validate contacts
          this.formContacts.forEach(async (el) => {
            if (el.id) {
              // update
              const m = el;
              if (m.service && m.service.id) {
                m.service = m.service.id;
              }
              if (m.owner && m.owner.id) {
                m.owner = m.owner.id;
              }
              m.organisation = newId;
              await updateContact(m.id, m).then((result) => console.log(result))
                .catch((err) => console.log(err));
            } else {
              // create
              const m = el;
              m.organisation = newId;
              await createContact(m);
            }
          });
          // validate equipments
          this.formEquipments.forEach(async (el) => {
            if (el.id) {
              // update
              const result = el;
              if (result.service_provider && result.service_provider.id) {
                result.service_provider = result.service_provider.id;
              }
              if (result.name && result.name.id) {
                result.name = result.name.id;
              }
              await updateEquipment(result.id, result);
            } else {
              // create
              const m = el;
              m.organisation = newId;
              if (m.service_provider && m.service_provider.id) {
                m.service_provider = m.service_provider.id;
              }
              if (m.name && m.name.id) {
                m.name = m.name.id;
              }
              await createEquipment(m);
            }
          });
          // validate propositions
          this.formPropositions.forEach(async (el) => {
            if (el.id) {
              // update
              const m = el;
              if (m.percent && m.percent.id) {
                m.percent = m.percent.id;
              }
              await updateProposition(m.id, m);
            } else {
              // create
              const m = el;
              m.organisation = newId;
              if (m.percent && m.percent.id) {
                m.percent = m.percent.id;
              }
              await createProposition(m);
            }
          });
        }).catch((err) => {
          console.log(err);
          this.$router.push({ name: 'dashboard_organisations', params: { type: 'error', text: 'Une erreur est survenue lors de la modification' } });
        })
          .finally(() => {
            this.$router.push({ name: 'dashboard_organisations', params: { type: 'success', text: 'La modification a été effectuée avec succès' } });
          });
      } else {
        this.validationDialog = true;
        console.log('error in child form!');
      }
    },
    stopEditingMaintenance() {
      if (this.$refs.formMaintenances && this.$refs.formMaintenances[0]) {
        const valid = this.$refs.formMaintenances[0].validate();
        if (valid) {
          const tmpId = this.formMaintenances[this.isEditingMaintenance].id || null;
          this.formMaintenances[this.isEditingMaintenance] = valid;
          this.formMaintenances[this.isEditingMaintenance].id = tmpId;
          this.isEditingMaintenance = null;
        } else {
          this.validationDialog = true;
        }
      }
    },
    stopEditingSubscription() {
      if (this.$refs.formSubscriptions && this.$refs.formSubscriptions[0]) {
        const valid = this.$refs.formSubscriptions[0].validate();
        if (valid) {
          const tmpId = this.formSubscriptions[this.isEditingSubscription].id || null;
          this.formSubscriptions[this.isEditingSubscription] = valid;
          this.formSubscriptions[this.isEditingSubscription].id = tmpId;
          this.isEditingSubscription = null;
        } else {
          this.validationDialog = true;
        }
      }
    },
    stopEditingContact() {
      if (this.$refs.formContacts && this.$refs.formContacts[0]) {
        const valid = this.$refs.formContacts[0].validate();
        if (valid) {
          const tmpId = this.formContacts[this.isEditingContact].id || null;
          this.formContacts[this.isEditingContact] = valid;
          this.formContacts[this.isEditingContact].id = tmpId;
          this.isEditingContact = null;
        } else {
          this.validationDialog = true;
        }
      }
    },
    stopEditingEquipment() {
      if (this.$refs.formEquipments && this.$refs.formEquipments[0]) {
        const valid = this.$refs.formEquipments[0].validate();
        if (valid) {
          const tmpId = this.formEquipments[this.isEditingEquipment].id || null;
          this.formEquipments[this.isEditingEquipment] = valid;
          this.formEquipments[this.isEditingEquipment].id = tmpId;
          this.isEditingEquipment = null;
        } else {
          this.validationDialog = true;
        }
      }
    },
    stopEditingProposition() {
      if (this.$refs.formPropositions && this.$refs.formPropositions[0]) {
        const valid = this.$refs.formPropositions[0].validate();
        if (valid) {
          const tmpId = this.formPropositions[this.isEditingProposition].id || null;
          this.formPropositions[this.isEditingProposition] = valid;
          this.formPropositions[this.isEditingProposition].id = tmpId;
          this.isEditingProposition = null;
        } else {
          this.validationDialog = true;
        }
      }
    },
    editMaintenance(id) {
      if (this.$refs.formMaintenances && this.$refs.formMaintenances[0]) {
        const valid = this.$refs.formMaintenances[0].validate();
        if (valid) {
          const tmpId = this.formMaintenances[this.isEditingMaintenance].id || null;
          this.formMaintenances[this.isEditingMaintenance] = valid;
          this.formMaintenances[this.isEditingMaintenance].id = tmpId;
          this.isEditingMaintenance = id;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.isEditingMaintenance = id;
      }
    },
    editSubscription(id) {
      if (this.$refs.formSubscriptions && this.$refs.formSubscriptions[0]) {
        const valid = this.$refs.formSubscriptions[0].validate();
        if (valid) {
          const tmpId = this.formSubscriptions[this.isEditingSubscription].id || null;
          this.formSubscriptions[this.isEditingSubscription] = valid;
          this.formSubscriptions[this.isEditingSubscription].id = tmpId;
          this.isEditingSubscription = id;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.isEditingSubscription = id;
      }
    },
    editContact(id) {
      if (this.$refs.formContacts && this.$refs.formContacts[0]) {
        const valid = this.$refs.formContacts[0].validate();
        if (valid) {
          const tmpId = this.formContacts[this.isEditingContact].id || null;
          this.formContacts[this.isEditingContact] = valid;
          this.formContacts[this.isEditingContact].id = tmpId;
          this.isEditingContact = id;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.isEditingContact = id;
      }
    },
    editEquipment(id) {
      if (this.$refs.formEquipments && this.$refs.formEquipments[0]) {
        const valid = this.$refs.formEquipments[0].validate();
        if (valid) {
          const tmpId = this.formEquipments[this.isEditingEquipment].id || null;
          this.formEquipments[this.isEditingEquipment] = valid;
          this.formEquipments[this.isEditingEquipment].id = tmpId;
          this.isEditingEquipment = id;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.isEditingEquipment = id;
      }
    },
    editProposition(id) {
      if (this.$refs.formPropositions && this.$refs.formPropositions[0]) {
        const valid = this.$refs.formPropositions[0].validate();
        if (valid) {
          const tmpId = this.formPropositions[this.isEditingProposition].id || null;
          this.formPropositions[this.isEditingProposition] = valid;
          this.formPropositions[this.isEditingProposition].id = tmpId;
          this.isEditingProposition = id;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.isEditingProposition = id;
      }
    },
    async deleteMaintenance(id) {
      if (this.$refs.formMaintenances && this.$refs.formMaintenances[0]
        && this.isEditingMaintenance !== id) {
        const valid = this.$refs.formMaintenances[0].validate();
        if (valid) {
          this.formMaintenances[this.isEditingMaintenance] = valid;
          this.isEditingMaintenance = null;
          if (this.formMaintenances[id].id) {
            await deleteMaintenance(this.formMaintenances[id].id);
          }
          this.formMaintenances.splice(id, 1);
        } else {
          this.validationDialog = true;
        }
      } else {
        if (this.formMaintenances[id].id) {
          await deleteMaintenance(this.formMaintenances[id].id);
        }
        this.formMaintenances.splice(id, 1);
        this.isEditingMaintenance = null;
      }
    },
    async deleteSubscription(id) {
      if (this.$refs.formSubscriptions && this.$refs.formSubscriptions[0]
        && this.isEditingSubscription !== id) {
        const valid = this.$refs.formSubscriptions[0].validate();
        if (valid) {
          this.formSubscriptions[this.isEditingSubscription] = valid;
          this.isEditingSubscription = null;
          if (this.formSubscriptions[id].id) {
            await deleteSubscription(this.formSubscriptions[id].id);
          }
          this.formSubscriptions.splice(id, 1);
        } else {
          this.validationDialog = true;
        }
      } else {
        if (this.formSubscriptions[id].id) {
          await deleteSubscription(this.formSubscriptions[id].id);
        }
        this.formSubscriptions.splice(id, 1);
        this.isEditingSubscription = null;
      }
    },
    async deleteContact(id) {
      if (this.$refs.formContacts && this.$refs.formContacts[0]) {
        if (this.isEditingContact !== id) {
          const valid = this.$refs.formContacts[0].validate();
          if (valid) {
            this.formContacts[this.isEditingContact] = valid;
            this.isEditingContact = null;
            if (this.formContacts[id].id) {
              await deleteContact(this.formContacts[id].id);
            }
            this.formContacts.splice(id, 1);
          } else {
            this.validationDialog = true;
          }
        } else {
          if (this.formContacts[id].id) {
            await deleteContact(this.formContacts[id].id);
          }
          this.formContacts.splice(id, 1);
          this.isEditingContact = null;
        }
      } else {
        if (this.formContacts[id].id) {
          await deleteContact(this.formContacts[id].id);
        }
        this.formContacts.splice(id, 1);
      }
    },
    async deleteEquipment(id) {
      if (this.$refs.formEquipments && this.$refs.formEquipments[0]) {
        if (this.isEditingEquipment !== id) {
          const valid = this.$refs.formEquipments[0].validate();
          if (valid) {
            this.formEquipments[this.isEditingEquipment] = valid;
            this.isEditingEquipment = null;
            if (this.formEquipments[id].id) {
              await deleteEquipment(this.formEquipments[id].id);
            }
            this.formEquipments.splice(id, 1);
          } else {
            this.validationDialog = true;
          }
        } else {
          if (this.formEquipments[id].id) {
            await deleteEquipment(this.formEquipments[id].id);
          }
          this.formEquipments.splice(id, 1);
          this.isEditingEquipment = null;
        }
      } else {
        if (this.formEquipments[id].id) {
          await deleteEquipment(this.formEquipments[id].id);
        }
        this.formEquipments.splice(id, 1);
      }
    },
    async deleteProposition(id) {
      if (this.$refs.formPropositions && this.$refs.formPropositions[0]) {
        if (this.isEditingProposition !== id) {
          const valid = this.$refs.formPropositions[0].validate();
          if (valid) {
            this.formPropositions[this.isEditingProposition] = valid;
            this.isEditingProposition = null;
            if (this.formPropositions[id].id) {
              await deleteProposition(this.formPropositions[id].id);
            }
            this.formPropositions.splice(id, 1);
          } else {
            this.validationDialog = true;
          }
        } else {
          if (this.formPropositions[id].id) {
            await deleteProposition(this.formPropositions[id].id);
          }
          this.formPropositions.splice(id, 1);
          this.isEditingProposition = null;
        }
      } else {
        if (this.formPropositions[id].id) {
          await deleteProposition(this.formPropositions[id].id);
        }
        this.formPropositions.splice(id, 1);
      }
    },
    async fetchOrganisation(newRoute) {
      this.loadingCategories = true;
      await getCatalogByName('Catégorie').then((res) => {
        this.categories = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingCategories = false;
      });

      this.loadingOrigines = true;
      await getCatalogByName('Origine').then((res) => {
        this.origines = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOrigines = false;
      });

      this.loadingSocietyTypes = true;
      await getCatalogByName('Modèle%20économique').then((res) => {
        this.societyTypeCatalog = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingSocietyTypes = false;
      });

      this.loadingForms = true;
      await getCatalogByName('Forme').then((res) => {
        this.forms = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingForms = false;
      });

      this.loadingSponsors = true;
      await getOrganisations().then((res) => {
        this.sponsors = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingSponsors = false;
      });

      this.loadingFamilies = true;
      await getCatalogByName('Famille').then((res) => {
        this.families = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingFamilies = false;
      });

      this.loadingSubfamilies = true;
      await getCatalogByName('Sous-famille').then((res) => {
        this.subfamilies = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingSubfamilies = false;
      });

      this.loadingMaintenances = true;
      getMaintenances().then((res) => {
        this.maintenances = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingMaintenances = false;
      });

      this.loadingFormMaintenances = true;
      getMaintenancesByOrganisation(decodeURIComponent(newRoute.params.id)).then((res) => {
        this.formMaintenances = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingFormMaintenances = false;
      });

      this.loadingFormSubscriptions = true;
      getSubscriptionsByOrganisation(decodeURIComponent(newRoute.params.id)).then((res) => {
        this.formSubscriptions = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingFormSubscriptions = false;
      });

      this.loadingFormContacts = true;
      getContactsByOrganisation(decodeURIComponent(newRoute.params.id)).then((res) => {
        this.formContacts = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingFormContacts = false;
      });

      this.loadingFormEquipments = true;
      getEquipmentsByOrganisation(decodeURIComponent(newRoute.params.id)).then((res) => {
        this.formEquipments = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingFormEquipments = false;
      });

      this.loadingFormPropositions = true;
      getPropositionsByOrganisation(decodeURIComponent(newRoute.params.id)).then((res) => {
        this.formPropositions = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingFormPropositions = false;
      });

      getOrganisation(decodeURIComponent(newRoute.params.id)).then((value) => {
        this.form = value.data;
        try {
          this.form.date = new Date(this.form.date).toISOString().substr(0, 10);
        } catch (err) {
          console.log(err);
        }
        console.log(value.data);
      })
        .catch((err) => console.log(err));
    },
    validate() {
      updateOrganisation(this.form).then(() => {
        this.$router.push({ name: 'dashboard_organisations', params: { type: 'success', text: 'La modification a été effectuée avec succès' } });
      });
    },
  },
  watch: {
    $route: {
      handler: 'fetchOrganisation',
      immediate: true,
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
  .contacts .v-input__slot {
    left:10px;
  }
</style>
