<template>
  <v-form class="ma-2"
          ref="form"
          v-model="valid"
          lazy-validation>
    <v-row align="center">
      <v-col cols="5" class="required short">
        <v-text-field v-model="form.name"
                      :rules="nameRules"
                      label="Nom"
                      placeholder="Société Durant"
                      required></v-text-field>
      </v-col>
      <v-col cols="3" class="short">
        <v-text-field label="Code"
                      v-model="form.code"
                      :rules="codeRules"
                      counter="10"></v-text-field>
      </v-col>
      <v-col cols="4" class="short">
        <v-text-field v-model="form.url"
                      label="Url site internet"
                      placeholder="www.monsite.fr"></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="3" class="short">
        <v-row class="d-flex flex-nowrap ml-2">
          <v-autocomplete v-model="form.categorie"
                          :items="categories.items"
                          item-text="name"
                          item-value="id"
                          dense
                          :loading="loadingCategories"
                          :label="categories.catalog.name"></v-autocomplete>
          <v-btn fab
                 x-small
                 dark
                 class="ma-auto ml-2 mt-3"
                 @click="editCatalog(categories.catalog.id)"
                 color="#6DD5CC"
                 style="top: 14px;">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="3" class="short">
        <v-row class="d-flex flex-nowrap ml-2">
          <v-autocomplete v-model="form.owner"
                          :items="owners.items"
                          item-text="name"
                          item-value="id"
                          dense
                          :loading="loadingOwners"
                          :label="owners.catalog.name"></v-autocomplete>
          <v-btn fab
                 x-small
                 dark
                 class="ma-auto ml-2 mt-3"
                 @click="editCatalog(owners.catalog.id)"
                 color="#6DD5CC"
                 style="top: 14px;">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="3" class="short">
        <v-row class="d-flex flex-nowrap ml-2">
          <v-autocomplete v-model="form.origin"
                          :items="origines.items"
                          item-text="name"
                          item-value="id"
                          dense
                          :loading="loadingOrigines"
                          :label="origines.catalog.name"></v-autocomplete>
          <v-btn fab
                 x-small
                 dark
                 class="ma-auto ml-2 mt-3"
                 @click="editCatalog(origines.catalog.id)"
                 color="#6DD5CC"
                 style="top: 14px;">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="3" class="short lower">
        <v-row class="d-flex flex-nowrap ml-2">
          <v-text-field v-model="form.date"
                        label="Date de création"
                        disabled></v-text-field>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
<!-- ADRESSE -->
        <v-row align='center'>
          <v-col cols="10">
            <h2 style="color: black;">Adresse</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="short">
            <v-text-field v-model="form.address1"
                          label="Adresse 1"
                          placeholder="10 Rue du Général"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="short">
            <v-text-field v-model="form.address2"
                          label="Adresse 2"
                          placeholder="ZAC du Mont"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="short">
            <v-text-field v-model="form.address3"
                          label="Adresse 3"
                          placeholder="Batiment 3B"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="short">
            <v-text-field v-model="form.postalCode"
                          label="Code postal"
                          placeholder="75001"></v-text-field>
          </v-col>
          <v-col cols="6" class="short">
            <v-text-field v-model="form.city"
                          label="Ville"
                          placeholder="Paris"></v-text-field>
          </v-col>
        </v-row>
<!-- SOCIETE -->
        <v-row>
          <v-col cols="10">
            <h2 style="color: black;">Société</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="short">
            <v-row class="d-flex flex-nowrap mr-1 ml-1">
              <v-autocomplete v-model="form.family"
                              :items="families.items"
                              item-text="name"
                              item-value="id"
                              dense
                              :loading="loadingFamilies"
                              :label="families.catalog.name"></v-autocomplete>
              <v-btn fab
                    x-small
                    dark
                    class="ma-auto ml-2 mt-3"
                    @click="editCatalog(families.catalog.id)"
                    color="#6DD5CC"
                    style="top: 14px;">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="short">
            <v-row class="d-flex flex-nowrap mr-1 ml-1">
              <v-autocomplete v-model="form.subfamily"
                              :items="subfamilies.items"
                              item-text="name"
                              item-value="id"
                              dense
                              :loading="loadingSubfamilies"
                              :label="subfamilies.catalog.name"></v-autocomplete>
              <v-btn fab
                    x-small
                    dark
                    class="ma-auto ml-2 mt-3"
                    @click="editCatalog(subfamilies.catalog.id)"
                    color="#6DD5CC"
                    style="top: 14px;">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="short">
            <v-row class="d-flex flex-nowrap mr-1 ml-1">
              <v-autocomplete v-model="form.societyType"
                              :items="societyTypeCatalog.items"
                              item-text="name"
                              item-value="id"
                              dense
                              :loading="loadingSocietyTypes"
                              :label="societyTypeCatalog.catalog.name"></v-autocomplete>
              <v-btn fab
                    x-small
                    dark
                    class="ma-auto ml-2 mt-3"
                    @click="editCatalog(societyTypeCatalog.catalog.id)"
                    color="#6DD5CC"
                    style="top: 14px;">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row align='center'>
          <v-col cols="3" class="short">
            <v-text-field v-model.number="form.ca"
                          :rules="caRules"
                          label="CA"></v-text-field>
          </v-col>
          <v-col cols="3" class="short">
            <v-text-field v-model.number="form.caMc"
                          label="CA - MC"
                          :rules="caMcRules"></v-text-field>
          </v-col>
          <v-col cols="3" class="short">
            <v-text-field v-model.number="form.workforce"
                          label="Effectif"
                          :rules="workforceRules"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="short">
            <v-text-field v-model="form.societyGroup"
                          label="Groupe / Maison mère"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row align='center'>
          <v-col cols="10">
            <h2 style="color: black;">Contact entreprise</h2>
          </v-col>
        </v-row>
        <v-row align='center'>
          <v-col cols="4" class="short">
            <v-row class="d-flex flex-nowrap ml-2">
              <v-autocomplete v-model="form.form"
                              :items="forms.items"
                              item-text="name"
                              item-value="id"
                              dense
                              :loading="loadingForms"
                              :label="forms.catalog.name"></v-autocomplete>
              <v-btn fab
                    x-small
                    dark
                    class="ma-auto ml-2 mt-3"
                    @click="editCatalog(forms.catalog.id)"
                    color="#6DD5CC"
                    style="top: 14px;">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="6" class="short lower">
            <v-row class="d-flex align-center ml-1">
              <v-text-field v-model="form.siret"
                            :rules="siretRules"
                            label="Siret"
                            placeholder="52918756900023">
              </v-text-field>
              <v-btn fab
                    dark
                    x-small
                    @click="searchSiret"
                    class="ma-auto ml-2 mt-3"
                    color="#6DD5CC"
                    style="top: 12px">
                <v-icon dark>
                  mdi-briefcase-search
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="1">
            <v-dialog v-model="siretDialog"
                      width="500">
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Recherche par SIRET
                </v-card-title>

                <v-card-text v-if="searchResult && searchResult.unite_legale
                                    && searchResult.unite_legale.denomination">
                  Une organisation a été trouvée : {{ searchResult.unite_legale.denomination }}.
                  <br />
                  Voici les informations associées : <br />
                  <ul>
                    <li>
                      Adresse : {{ searchResult.unite_legale.etablissement_siege.numero_voie }}
                      {{ searchResult.unite_legale.etablissement_siege.indice_repetition }}
                      {{ searchResult.unite_legale.etablissement_siege.type_voie }}
                      {{ searchResult.unite_legale.etablissement_siege.libelle_voie }}
                      {{ searchResult.unite_legale.etablissement_siege.code_postal }}
                      {{ searchResult.unite_legale.etablissement_siege.libelle_commune }}
                    </li>
                    <li>
                      Effectif : {{
                        searchResult.unite_legale.etablissement_siege
                        .tranche_effectifs
                      }}
                    </li>
                  </ul>
                  Voulez-vous préremplir les champs associés ?
                </v-card-text>
                <v-card-text v-else>
                  <div v-if="searchResult">
                    Une erreur s'est produite. La recherche n'a pas pu aboutir.
                  </div>
                  <v-progress-circular v-else
                                      indeterminate
                                      color="#6DD5CC"></v-progress-circular>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions v-if="searchResult && searchResult.unite_legale
                                    && searchResult.unite_legale.denomination">
                  <v-spacer></v-spacer>
                  <v-btn color="error"
                        text
                        @click="siretDialog = false; searchResult=null;">
                    Non, remplir à la main
                  </v-btn>
                  <v-btn color="#6DD5CC"
                        text
                        @click="siretDialog = false; fillFields();">
                    Préremplir
                  </v-btn>
                </v-card-actions>
                <v-card-actions v-else>
                  <v-spacer></v-spacer>
                  <v-btn color="error"
                        text
                        @click="siretDialog = false; searchResult=null;">
                    Retour
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="10" class="short">
            <v-text-field v-model="form.APECode"
                          label="Code APE"></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="10" class="short">
            <v-text-field v-model="form.phone"
                          label="Téléphone"></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="10" class="short">
            <v-text-field v-model="form.email"
                          :rules="emailRules"
                          label="E-mail général"></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" class="parrain">
          <v-col cols="10" class="short">
            <v-autocomplete v-model="form.sponsor"
                            :items="sponsors"
                            item-text="name"
                            item-value="id"
                            dense
                            :loading="loadingSponsors"
                            label="Parrain"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="10" class="short">
            <v-textarea v-model="form.notes"
                        label="Notes"></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
      <v-dialog v-model="catalogDialog"
                width="500">
        <CatalogForm :catalogId="selectedCatalog" v-on:closeCatalog="closeCatalog" />
      </v-dialog>
  </v-form>
</template>

<script>
import { getCatalogByName } from '@/services/catalog.service';
import { getOrganisations } from '@/services/organisation.service';
import CatalogForm from '@/components/CatalogForm.vue';
// import { deleteUser } from '@/services/user.service';
// import { defineComponent, ref, onMounted } from 'vue';
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  name: 'OrganisationForm',
  props: ['organisation'],
  components: {
    CatalogForm,
  },
  data: () => ({
    nameRules: [
      (v) => !!v || 'Le champ est requis.',
    ],
    ownerRules: [
    ],
    emailRules: [
      (v) => !v || (v && v.length === 0) || /.+@.+\..+/.test(v) || 'Le champ est invalide.',
    ],
    codeRules: [
      (v) => (!v || (v && v.length === 0) || (v && v.length <= 10)) || 'Le champ est invalide.',
    ],
    siretRules: [
      (v) => (!v || (v && v.length === 0) || (v && v.length === 14)) || 'Le champ est invalide.',
    ],
    caRules: [
      (v) => (!v || (!Number.isNaN(Number(v)) && Number(v) > 0)) || 'Le champ est invalide.',
    ],
    caMcRules: [
      (v) => (!v || (!Number.isNaN(Number(v)) && Number(v) > 0)) || 'Le champ est invalide.',
    ],
    workforceRules: [
      (v) => (!v || (Number.isInteger(Number(v)) && Number(v) > 0)) || 'Le champ est invalide.',
    ],
    valid: true,
    dateDialog: false,
    siretDialog: false,
    searchResult: null,
    catalogDialog: false,
    selectedCatalog: null,
    loadingSocietyTypes: false,
    societyTypeCatalog: { catalog: { name: '' }, items: [] },
    loadingCategories: false,
    categories: { catalog: { name: '' }, items: [] },
    loadingOwners: false,
    owners: { catalog: { name: '' }, items: [] },
    loadingOrigines: false,
    origines: { catalog: { name: '' }, items: [] },
    loadingForms: false,
    forms: { catalog: { name: '' }, items: [] },
    loadingSponsors: false,
    sponsors: [],
    loadingFamilies: false,
    families: { catalog: { name: '' }, items: [] },
    loadingSubfamilies: false,
    subfamilies: { catalog: { name: '' }, items: [] },
  }),
  created() {
    this.fetchCatalogs();
  },
  computed: {
    form() {
      return this.organisation ? this.organisation : {
        date: new Date().toISOString().substr(0, 10),
      };
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchCatalogs();
      },
    },
  },
  methods: {
    fetchCatalogs() {
      this.loadingCategories = true;
      getCatalogByName('Catégorie').then((res) => {
        this.categories = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingCategories = false;
      });

      this.loadingOwners = true;
      getCatalogByName('Owner').then((res) => {
        this.owners = res.data;
        this.form.owner = this.form.owner || this.owners.items[0].id;
        console.log(this.form.owner);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOwners = false;
      });

      this.loadingOrigines = true;
      getCatalogByName('Origine').then((res) => {
        this.origines = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOrigines = false;
      });

      this.loadingSocietyTypes = true;
      getCatalogByName('Modèle%20économique').then((res) => {
        this.societyTypeCatalog = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingSocietyTypes = false;
      });

      this.loadingForms = true;
      getCatalogByName('Forme').then((res) => {
        this.forms = res.data;
        console.log(res.data);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingForms = false;
      });

      this.loadingSponsors = true;
      getOrganisations().then((res) => {
        this.sponsors = res.data;
        console.log(res.data);
        console.log(res.data[0].id);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingSponsors = false;
      });

      this.loadingFamilies = true;
      getCatalogByName('Famille').then((res) => {
        this.families = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingFamilies = false;
      });

      this.loadingSubfamilies = true;
      getCatalogByName('Sous-famille').then((res) => {
        this.subfamilies = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingSubfamilies = false;
      });
    },
    validate() {
      // send to parent
      if (this.$refs.form.validate()) {
        console.log('submit!');
        this.form.date = new Date().toISOString().substr(0, 10);
        if (this.form.family) {
          this.form.family = this.form.family.id || this.form.family;
        }
        if (this.form.subfamily) {
          this.form.subfamily = this.form.subfamily.id || this.form.subfamily;
        }
        return this.form;
      }
      console.log('error submit!!');
      return null;
    },
    editCatalog(catalogId) {
      this.catalogDialog = true;
      this.selectedCatalog = catalogId;
    },
    closeCatalog() {
      this.fetchCatalogs();
      this.catalogDialog = false;
      this.selectedCatalog = null;
    },
    searchSiret() {
      this.siretDialog = true;
      if (this.form.siret && this.form.siret.length === 14) {
        axios.get(`https://entreprise.data.gouv.fr/api/sirene/v3/etablissements/${this.form.siret}`)
          .then((res) => {
            this.searchResult = res.data.etablissement;
            console.log(this.searchResult);
          })
          .catch((err) => {
            this.searchResult = err;
          });
      } else {
        this.searchResult = { message: 'erreur' };
      }
    },
    fillFields() {
      if (this.searchResult.unite_legale.denomination) {
        this.form.name = this.searchResult.unite_legale.denomination;
      }
      if (this.searchResult.unite_legale.etablissement_siege.code_postal) {
        this.form.postalCode = this.searchResult.unite_legale.etablissement_siege.code_postal;
      }
      if (this.searchResult.unite_legale.etablissement_siege.type_voie
        && this.searchResult.unite_legale.etablissement_siege.libelle_voie) {
        this.form.address1 = `${this.searchResult.unite_legale.etablissement_siege.numero_voie || ''}
${this.searchResult.unite_legale.etablissement_siege.indice_repetition || ''}
${this.searchResult.unite_legale.etablissement_siege.type_voie}
${this.searchResult.unite_legale.etablissement_siege.libelle_voie}`;
      }
      if (this.searchResult.complement_adresse) {
        this.form.address2 = this.searchResult.complement_adresse;
      }
      if (this.searchResult.complement_adresse_2) {
        this.form.address3 = this.searchResult.complement_adresse_2;
      }
      if (this.searchResult.libelle_commune) {
        this.form.city = this.searchResult.libelle_commune;
      }
      if (this.searchResult.libelle_pays_etranger) {
        this.form.country = this.searchResult.libelle_pays_etranger;
      } else {
        this.form.country = 'France';
      }
      if (this.searchResult.unite_legale.etablissement_siege.tranche_effectifs) {
        this.form.workforce = this.searchResult.unite_legale.etablissement_siege.tranche_effectifs;
      }
      if (this.searchResult.unite_legale.categorie_entreprise) {
        const type = this.searchResult.unite_legale.categorie_entreprise;
        const item = this.societyTypeCatalog.items.find((el) => el.name === type);
        this.form.societyType = item ? item.id : null;
      }
    },
    ...mapActions({
      register: 'auth/register',
    }),
  },
};
</script>

<style>
  .v-autocomplete .v-input__slot {
    min-height: 56px !important;
    max-height: 56px !important;
  }
  .short {
    padding: 0px 12px;
    min-height: 70px;
  }
  .v-autocomplete .v-input__slot {
    left:-8px;
  }
  .v-select__slot {
    top: 12px;
  }
  .parrain .v-autocomplete .v-input__slot {
    left: 0px;
  }
  .lower .v-input__slot {
    top: 12px;
  }
  h2 {
    margin: revert;
  }
  .parrain .v-input__slot {
    top:-16px;
  }
</style>
